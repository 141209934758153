import classNames from 'classnames';
import { animate, motion, useMotionValue } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { lock, unlock } from 'tua-body-scroll-lock';
import useResizeObserver from 'use-resize-observer';

import { CloseRemoveXIcon } from '@jernia/shared/components/icons/icons';

import Button from '../button/button';
import ScrollFade from '../scroll-fade/scroll-fade';

type ModalSize = 'large' | 'medium' | 'screen';

type Props = {
  isOpen: boolean;
  size?: ModalSize;
  noPadding?: boolean;
  onRequestClose?: () => void;
  onAfterClose?: () => void;
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  footerClassName?: string;
  lessHeaderPadding?: boolean;
  bgGrey?: boolean;
  blueHeaderBg?: boolean;
  withScrollFade?: boolean;
};

export function Modal({
  isOpen,
  size,
  footerClassName,
  noPadding,
  lessHeaderPadding,
  withScrollFade,
  onRequestClose,
  onAfterClose,
  header,
  children,
  footer,
  bgGrey,
  blueHeaderBg,
}: Props) {
  const height = useMotionValue(0);

  const { ref } = useResizeObserver({
    onResize: (rect) => {
      if (rect.height) {
        animate(height, rect.height);
      }
    },
  });

  let width = 'max-w-[407px]';
  if (size === 'large') width = 'max-w-[800px]';
  if (size === 'medium') width = 'max-w-[518px]';
  if (size === 'screen') width = 'max-w-full';
  return (
    <ReactModal
      isOpen={isOpen}
      parentSelector={() => {
        return document.getElementById('next-modals') ?? document.body;
      }}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      portalClassName="jernia-ui ReactModalPortal"
      overlayClassName={{
        base: `
            fixed
            z-30
            w-full
            h-full
            top-0
            left-0
            flex
            items-center
            justify-center
            bg-utility-backdrop
            transition-opacity
            duration-300
          `,
        afterOpen: isOpen ? 'opacity-100' : '',
        beforeClose: 'opacity-0',
      }}
      className={{
        base: `
            fixed
            max-h-full
            bg-white
            ${width}
            w-[calc(100%-1.5rem)]
            transform
            rounded
            outline-none
            transition-transform
            duration-300
            ${size === 'screen' ? ' h-[calc(100%-1.5rem)]' : ''}
          `,
        afterOpen: isOpen ? 'scale-100' : '',
        beforeClose: 'scale-90',
      }}
      closeTimeoutMS={300}
    >
      <motion.div
        className="overflow-hidden"
        style={{ height: size === 'screen' ? undefined : height }}
      >
        <div
          ref={ref}
          className={classNames('calculated-dvh flex flex-col', {
            'h-full': size === 'screen',
          })}
        >
          <ModalHeader
            onClose={onRequestClose}
            bgGrey={bgGrey}
            blueHeaderBg={blueHeaderBg}
            lessHeaderPadding={lessHeaderPadding}
          >
            {header}
          </ModalHeader>
          <ModalBody
            noPadding={noPadding}
            bgGrey={bgGrey}
            size={size}
            withScrollFade={withScrollFade}
            hasFooter={Boolean(footer)}
          >
            {children}
          </ModalBody>
          {footer ? (
            <ModalFooter bgGrey={bgGrey} className={footerClassName}>
              {footer}
            </ModalFooter>
          ) : null}
        </div>
      </motion.div>
    </ReactModal>
  );
}

function ModalHeader({
  children,
  onClose,
  bgGrey,
  blueHeaderBg,
  lessHeaderPadding,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  bgGrey?: boolean;
  blueHeaderBg?: boolean;
  lessHeaderPadding?: boolean;
}) {
  if (!children && onClose)
    return (
      <div className="absolute right-4 top-4 z-10 p-2">
        <Button kind="secondary-dark" onClick={onClose} size="large">
          <CloseRemoveXIcon size={5} />
        </Button>
      </div>
    );

  return (
    <div
      className={classNames(
        'flex items-center justify-between border-neutral-500 rounded-t',
        !blueHeaderBg ? 'border-b' : '',
        lessHeaderPadding ? 'p-4' : 'px-8 py-4',
        bgGrey || blueHeaderBg ? 'bg-primary-100 border-neutral-500' : ''
      )}
    >
      <h3 className="text-1xl font-medium">{children}</h3>
      {onClose && (
        <button
          className="flex size-12 shrink-0 items-center justify-center rounded hover:bg-primary-300 focus:outline-none focus:ring"
          onClick={onClose}
        >
          <CloseRemoveXIcon />
        </button>
      )}
    </div>
  );
}

function ModalBody({
  children,
  noPadding,
  hasFooter,
  bgGrey,
  withScrollFade,
  size,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  hasFooter: boolean;
  bgGrey?: boolean;
  withScrollFade?: boolean;
  size?: ModalSize;
}) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const content = contentRef.current;
    // lock
    if (content) {
      lock([content], {
        overflowType: 'clip',
        useGlobalLockState: true,
      });
    }

    return () => {
      unlock([content], {
        useGlobalLockState: true,
      });
    };
  }, []);

  const content = (
    <div
      ref={contentRef}
      className={classNames(
        'rounded-b',
        withScrollFade ? '' : 'overflow-y-auto',
        hasFooter ? 'pb-4' : '',
        noPadding ? '' : 'p-4 md:p-8',
        bgGrey ? 'bg-neutral-300' : '',
        size === 'screen' ? 'max-h-full' : 'max-h-[80vh]'
      )}
    >
      {children}
    </div>
  );
  if (!withScrollFade) {
    return content;
  }

  return (
    <ScrollFade horizontal={false} className="flex flex-col overflow-hidden">
      {content}
    </ScrollFade>
  );
}

function ModalFooter({
  bgGrey,
  children,
  className,
}: {
  children: React.ReactNode;
  bgGrey?: boolean;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'p-4 pt-4 md:p-8 z-20',
        bgGrey ? 'bg-neutral-300' : 'bg-neutral-100',
        className
      )}
    >
      {children}
    </div>
  );
}
