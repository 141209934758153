import { useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref, horizontal?: boolean, callback?: any) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      if (!current) return;

      const hasOverflow = horizontal
        ? current.scrollWidth > current.clientWidth
        : current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    const observer = new ResizeObserver(() => {
      trigger();
    });

    if (current) {
      observer.observe(current);
      trigger();
    }

    if (typeof window !== 'undefined') {
      window?.addEventListener('resize', trigger);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
      if (typeof window !== 'undefined') {
        window?.removeEventListener('resize', trigger);
      }
    };
  }, [horizontal, callback, ref]);

  return isOverflow;
};
