import classnames from 'classnames';
import { formatUrl } from 'next/dist/shared/lib/router/utils/format-url';
import Link from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';
import React, { cloneElement, isValidElement } from 'react';

type Size = 'small' | 'medium' | 'large' | 'none';

type Kind =
  | 'primary-dark'
  | 'primary-light'
  | 'secondary-light'
  | 'secondary-dark'
  | 'outline-light'
  | 'outline-dark'
  | 'red-light'
  | 'red-dark'
  | 'green-light'
  | 'green-dark'
  | 'rent-light'
  | 'rent-dark'
  | 'vipps'
  | 'native'
  | 'primary-dark-link'
  | 'primary-light-link'

  // This should've probably been named "secondary", but it's already used
  // for a variation of the primary color in this context.
  | 'orange';

type BaseProps = {
  className?: string;
  kind?: Kind;
  size?: Size;
  children: React.ReactNode;
  leftIcon?: React.ReactElement<React.HTMLAttributes<SVGSVGElement>>;
  rightIcon?: React.ReactElement<React.HTMLAttributes<SVGSVGElement>>;
  isDummy?: boolean;
};

export type ButtonProps = BaseProps & {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

function getClassName({
  className,
  kind,
  size,
  isDisabled = false,
}: {
  className: ButtonProps['className'];
  size: ButtonProps['size'];
  kind: ButtonProps['kind'];
  isDisabled?: boolean;
}) {
  return classnames(
    className,
    'inline-flex items-center justify-center whitespace-nowrap rounded text-center',
    'leading-none transition-colors disabled:cursor-not-allowed',

    !className?.includes('font-') ? 'font-semibold' : undefined,
    // sizes
    {
      'h-7 gap-x-1 p-2 text-xs': size === 'small',
      'h-9.5 gap-x-1.5 p-3 text-sm': size === 'medium',
      'h-12 gap-x-2 px-3.5 py-4 text-p-md': size === 'large',
    },
    // kinds
    {
      'bg-primary-700 text-white hover:bg-primary-800':
        kind === 'primary-dark' && !isDisabled,
      'bg-primary-500 text-primary-800 hover:bg-primary-300':
        kind === 'primary-light' && !isDisabled,

      'bg-primary-100 text-primary-700 hover:bg-primary-300 hover:text-primary-800':
        kind === 'secondary-dark' && !isDisabled,
      'bg-primary-700 text-neutral-100 hover:bg-primary-300 hover:text-primary-800':
        kind === 'secondary-light' && !isDisabled,

      'border border-primary-700 text-primary-700 hover:bg-primary-800 hover:text-neutral-100':
        kind === 'outline-dark' && !isDisabled,

      'border border-primary-500 text-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-primary-800':
        kind === 'outline-light' && !isDisabled,

      'bg-success-700 text-white hover:bg-success-800':
        kind === 'green-dark' && !isDisabled,
      'bg-success-300 text-success-800 hover:bg-success-100 hover:text-success-800':
        kind === 'green-light' && !isDisabled,

      'bg-danger-100 text-danger-700 hover:bg-danger-300 hover:text-text-800':
        kind === 'red-light' && !isDisabled,
      'bg-danger-800 text-danger-100 hover:bg-danger-100 hover:text-danger':
        kind === 'red-dark' && !isDisabled,
      'bg-rent-700 text-white hover:bg-rent-800':
        kind === 'rent-dark' && !isDisabled,
      'bg-rent-300 text-rent-800 hover:bg-rent-100':
        kind === 'rent-light' && !isDisabled,
      'text-primary-700 underline hover:no-underline':
        kind === 'primary-dark-link' && !isDisabled,
      'text-primary-500 underline hover:no-underline':
        kind === 'primary-light-link' && !isDisabled,

      'rounded-none hover:text-primary-700': kind === 'native' && !isDisabled,

      'bg-brand-vipps text-white': kind === 'vipps',

      'bg-secondary-700 text-white hover:bg-secondary-500':
        kind === 'orange' && !isDisabled,
    },
    // disable state
    {
      '!bg-neutral-700 !text-white':
        ['primary-dark', 'green-dark', 'rent-dark'].includes(kind) &&
        isDisabled,

      '!bg-neutral-500 !text-text-700':
        [
          'secondary-dark',
          'red-dark',
          'primary-light',
          'secondary-light',
          'green-light',
          'red-light',
          'rent-light',
        ].includes(kind) && isDisabled,

      'border !border-neutral-500 !text-neutral-500':
        ['outline-dark', 'outline-light'].includes(kind) && isDisabled,

      'text-neutral-500': kind === 'native' && isDisabled,
    }
  );
}

// extra

type RenderChildrenProp = {
  size: BaseProps['size'];
  leftIcon: BaseProps['leftIcon'];
  children: BaseProps['children'];
  rightIcon: BaseProps['rightIcon'];
};

function renderChildren({
  size,
  leftIcon,
  children,
  rightIcon,
}: RenderChildrenProp) {
  return (
    <>
      {leftIcon && isValidElement(leftIcon)
        ? cloneElement(leftIcon, {
            title: '',
            'aria-hidden': true,
            className: classnames(leftIcon.props.className, 'shrink-0', {
              'size-3': size === 'small',
              'size-4': size === 'medium',
              'size-5': size === 'large',
            }),
          })
        : null}
      {children}
      {rightIcon && isValidElement(rightIcon)
        ? cloneElement(rightIcon, {
            title: '',
            'aria-hidden': true,
            className: classnames(rightIcon.props.className, 'shrink-0', {
              'size-3': size === 'small',
              'size-4': size === 'medium',
              'size-5': size === 'large',
            }),
          })
        : null}
    </>
  );
}

export const Button = ({
  kind = 'primary-dark',
  size = 'large',
  disabled,
  className,
  children,
  leftIcon,
  rightIcon,
  onClick,
  isDummy = false,
  ...rest
}: ButtonProps) => {
  if (isDummy) {
    return (
      <span
        className={getClassName({
          kind,
          size,
          className,
          isDisabled: disabled,
        })}
      >
        {renderChildren({ size, leftIcon, children, rightIcon })}
      </span>
    );
  }

  return (
    <button
      className={getClassName({ kind, size, className, isDisabled: disabled })}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {renderChildren({ size, leftIcon, children, rightIcon })}
    </button>
  );
};

type LinkProps = BaseProps & {
  href: NextLinkProps['href'];
  shallow?: NextLinkProps['shallow'];
  disabled?: boolean;
  useNextLink?: boolean;
  isExternal?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function ButtonLink({
  kind = 'primary-dark',
  size = 'large',
  useNextLink = false,
  leftIcon,
  rightIcon,
  disabled,
  className,
  children,
  shallow,
  onClick,
  isExternal = false,
  href,
}: LinkProps) {
  if (disabled) {
    return (
      <Button
        disabled
        kind={kind}
        size={size}
        className={className}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
      >
        {children}
      </Button>
    );
  }

  if (useNextLink) {
    return (
      <Link
        href={href}
        onClick={onClick}
        shallow={shallow}
        className={getClassName({ kind, size, className })}
      >
        {renderChildren({ size, leftIcon, children, rightIcon })}
      </Link>
    );
  }

  const hrefString = typeof href === 'string' ? href : formatUrl(href);
  return (
    <a
      href={hrefString}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      className={getClassName({ kind, size, className })}
    >
      {renderChildren({ size, leftIcon, children, rightIcon })}
    </a>
  );
}

export default Button;
