import classNames from 'classnames';
import { useRef, useState } from 'react';

import { useIsOverflow } from '@jernia/shared/hooks/is-overflow';

function Shadow({
  position,
  isVisible,
  fadeToPrimaryBg,
}: {
  position: 'top' | 'right' | 'bottom' | 'left';
  isVisible: boolean;
  fadeToPrimaryBg?: boolean;
}) {
  const shadowClasses = classNames(
    'absolute z-10  transition-opacity duration-500 ease-out pointer-events-none',
    {
      'opacity-100': isVisible,
      'opacity-0': !isVisible,
      'from-white to-transparent': !fadeToPrimaryBg,
      'from-primary-100 to-transparent': fadeToPrimaryBg,

      // Vertical
      'h-20': position === 'top' || position === 'bottom',
      'top-0 left-0 right-0 bg-gradient-to-b': position === 'top',
      'bottom-0 left-0 right-0 bg-gradient-to-t': position === 'bottom',

      // Horizontal
      'w-20': position === 'left' || position === 'right',
      'top-0 bottom-0 left-0 bg-gradient-to-r': position === 'left',
      'top-0 bottom-0 right-0 bg-gradient-to-l': position === 'right',
    }
  );

  return <div className={shadowClasses} />;
}

export default function ScrollFade({
  horizontal,
  fadeToPrimaryBg,
  children,
  className,
  hidden,
}: {
  horizontal?: boolean;
  fadeToPrimaryBg?: boolean;
  children: React.ReactNode;
  className?: string;
  hidden?: boolean;
}) {
  const ref = useRef(null);
  const [scrollPos, setScrollPos] = useState<'start' | 'end' | 'transit'>(
    'start'
  );

  const isScrollable = useIsOverflow(ref, horizontal);

  function handleScroll() {
    const threshold = 10;
    const container = ref.current;

    const scrollPos = horizontal ? container.scrollLeft : container.scrollTop;

    const scrollSize = horizontal
      ? container.scrollWidth
      : container.scrollHeight;

    const clientSize = horizontal
      ? container.clientWidth
      : container.clientHeight;

    if (scrollPos < threshold) {
      setScrollPos('start');
    } else if (scrollPos + clientSize > scrollSize - threshold) {
      setScrollPos('end');
    } else {
      setScrollPos('transit');
    }
  }

  return (
    <div className={classNames('relative', className)}>
      {!hidden && (
        <Shadow
          position={horizontal ? 'left' : 'top'}
          isVisible={
            isScrollable && (scrollPos === 'end' || scrollPos === 'transit')
          }
          fadeToPrimaryBg={fadeToPrimaryBg}
        />
      )}
      <div
        ref={ref}
        className="no-scrollbars h-full overflow-auto  "
        onScroll={handleScroll}
      >
        {children}
      </div>
      {!hidden && (
        <Shadow
          position={horizontal ? 'right' : 'bottom'}
          isVisible={
            isScrollable && (scrollPos === 'start' || scrollPos === 'transit')
          }
          fadeToPrimaryBg={fadeToPrimaryBg}
        />
      )}
    </div>
  );
}
